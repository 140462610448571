var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{class:_vm.clienteComponent ? 'px-3' : ''},[_c('v-card',{attrs:{"flat":_vm.clienteComponent ? true : false}},[(!_vm.clienteComponent)?_c('v-card-title',{class:_vm.clienteComponent
          ? ' text-h5 font-weight-bold pa-3'
          : 'accent text-h5 font-weight-bold pa-3'},[_c('v-icon',{attrs:{"color":"primary","left":""}},[_vm._v("mdi-toolbox-outline")]),_vm._v(" Relatório Duração das Atividades "),_c('v-spacer')],1):_vm._e(),(!_vm.clienteComponent)?_c('v-divider'):_vm._e(),_c('v-card-text',[_c('v-card-title',[_c('v-text-field',{staticStyle:{"max-width":"200px"},attrs:{"append-icon":"mdi-magnify","label":"Buscar","clearable":"","dense":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-card',{staticClass:"d-flex mr-6 align-center",staticStyle:{"overflow":"hidden"},attrs:{"outlined":"","height":"40px"}},[_c('v-btn',{staticClass:"px-1",attrs:{"color":"white","tile":"","depressed":"","height":"40px","min-width":"40px"},on:{"click":_vm.subMonth}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1),_c('v-divider',{staticClass:"ma-0",attrs:{"vertical":""}}),_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":true,"return-value":_vm.dataCalendario,"transition":"slide-x-reverse-transition","slide-y-transition":"","offset-y":""},on:{"update:returnValue":function($event){_vm.dataCalendario=$event},"update:return-value":function($event){_vm.dataCalendario=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.mostraMes)+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-calendar")])],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"type":"month","color":"blue","no-title":"","scrollable":""},on:{"change":function($event){return _vm.$refs.menu.save(_vm.dataCalendario)}},model:{value:(_vm.dataCalendario),callback:function ($$v) {_vm.dataCalendario=$$v},expression:"dataCalendario"}})],1),_c('v-divider',{staticClass:"ma-0",attrs:{"vertical":""}}),_c('v-btn',{staticClass:"px-1",attrs:{"color":"white","tile":"","depressed":"","height":"40px","min-width":"40px"},on:{"click":_vm.addMonth}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1),_c('v-btn',{staticClass:"white--text ",attrs:{"color":"primary","disabled":!_vm.listaAtividades.length},on:{"click":_vm.gerarpdf}},[_vm._v(" PDF ")])],1),_c('v-divider'),_c('v-card-text',[_c('v-data-table',{staticClass:"data-tables data-tables__row-click",attrs:{"headers":_vm.headers,"search":_vm.search,"items":_vm.listaAtividades,"items-per-page":10,"sort-desc":true},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
          var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.status == 0
                  ? 'grey'
                  : item.status == 1
                  ? 'blue-grey'
                  : item.status == 2
                  ? 'yellow darken-4'
                  : item.status == 3
                  ? 'green'
                  : item.status == 4
                  ? 'purple'
                  : item.status == 5
                  ? 'green darken-4'
                  : item.status == 6
                  ? 'deep-purple accent-4'
                  : item.status == -1
                  ? 'red'
                  : 'light-green',"small":"","left":""}},[_vm._v("mdi-square-rounded")]),_vm._v(_vm._s(_vm._f("statusAtividade")(item.status,item.entrega))+" ")]}},{key:"item.inicio",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.inicio,"dd/MM/yyyy"))+" ")]}},{key:"item.entrega",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.entrega,"dd/MM/yyyy"))+" ")]}},{key:"item.prioridade",fn:function(ref){
                  var item = ref.item;
return [(item.prioridade === 1)?_c('v-icon',{attrs:{"color":"prioridade_baixa"}},[_vm._v(" mdi-flag Baixa ")]):_vm._e(),(item.prioridade === 2)?_c('v-icon',{attrs:{"color":"prioridade_normal"}},[_vm._v(" mdi-flag Normal ")]):_vm._e(),(item.prioridade === 3)?_c('v-icon',{attrs:{"color":"prioridade_urgente"}},[_vm._v(" mdi-flag Urgente ")]):_vm._e(),(item.prioridade === 4)?_c('v-icon',{attrs:{"color":"prioridade_emergencia"}},[_vm._v(" mdi-flag Emergência ")]):_vm._e()]}}])})],1)],1),(_vm.pdfDialog)?_c('PdfDialogComp',{attrs:{"pdfDialog":_vm.pdfDialog,"pdfData":_vm.pdfData,"title":"RELATÓRIO DURAÇÃO DAS ATIVIDADES"},on:{"update:pdfDialog":function($event){_vm.pdfDialog=$event},"update:pdf-dialog":function($event){_vm.pdfDialog=$event}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }