import api from "../../axios_service.js";

export async function fetchTotalAtividadesClientes(id, filtros) {
  const response = await api.get(
    `/cliente/relatorios/total-atividades-clientes/${id}${
      filtros ? filtros : ""
    }`
  );
  return response.data.result;
}
