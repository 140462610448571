<template>
  <v-container :class="clienteComponent ? 'px-3' : ''">
    <v-card :flat="clienteComponent ? true : false">
      <v-card-title
        v-if="!clienteComponent"
        :class="
          clienteComponent
            ? ' text-h5 font-weight-bold pa-3'
            : 'accent text-h5 font-weight-bold pa-3'
        "
      >
        <v-icon color="primary" left>mdi-toolbox-outline</v-icon>
        Relatório Duração das Atividades
        <v-spacer></v-spacer>
      </v-card-title>
      <v-divider v-if="!clienteComponent"></v-divider>
      <v-card-text>
        <v-card-title>
          <v-text-field
            style="max-width: 200px;"
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar"
            clearable
            dense
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-card
            outlined
            class="d-flex mr-6 align-center"
            height="40px"
            style="overflow: hidden"
          >
            <v-btn
              @click="subMonth"
              color="white"
              tile
              depressed
              height="40px"
              class="px-1"
              min-width="40px"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-divider vertical class="ma-0"></v-divider>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="true"
              :return-value.sync="dataCalendario"
              transition="slide-x-reverse-transition"
              slide-y-transition
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" text>
                  {{ mostraMes }}
                  <v-icon right>mdi-calendar</v-icon>
                </v-btn>
              </template>
              <v-date-picker
                v-model="dataCalendario"
                type="month"
                color="blue"
                no-title
                scrollable
                @change="$refs.menu.save(dataCalendario)"
              >
                <!-- <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false">
              Cancel
            </v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(search)">
              OK
            </v-btn> -->
              </v-date-picker>
            </v-menu>
            <v-divider vertical class="ma-0"></v-divider>
            <v-btn
              @click="addMonth"
              color="white"
              tile
              depressed
              height="40px"
              class="px-1"
              min-width="40px"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-card>
          <v-btn
            color="primary"
            class="white--text "
            :disabled="!listaAtividades.length"
            @click="gerarpdf"
          >
            <!-- <v-icon left>mdi-file-pdf-box</v-icon> -->
            PDF
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :search="search"
            :items="listaAtividades"
            :items-per-page="10"
            :sort-desc="true"
            class="data-tables data-tables__row-click"
          >
            <template v-slot:item.status="{ item }">
              <v-icon
                class=""
                :color="
                  item.status == 0
                    ? 'grey'
                    : item.status == 1
                    ? 'blue-grey'
                    : item.status == 2
                    ? 'yellow darken-4'
                    : item.status == 3
                    ? 'green'
                    : item.status == 4
                    ? 'purple'
                    : item.status == 5
                    ? 'green darken-4'
                    : item.status == 6
                    ? 'deep-purple accent-4'
                    : item.status == -1
                    ? 'red'
                    : 'light-green'
                "
                small
                left
                >mdi-square-rounded</v-icon
              >{{ item.status | statusAtividade(item.entrega) }}
            </template>
            <template v-slot:item.inicio="{ item }">
              {{ item.inicio | dateFormat("dd/MM/yyyy") }}
            </template>
            <template v-slot:item.entrega="{ item }">
              {{ item.entrega | dateFormat("dd/MM/yyyy") }}
            </template>
            <template v-slot:item.prioridade="{ item }">
              <v-icon v-if="item.prioridade === 1" color="prioridade_baixa">
                mdi-flag Baixa
              </v-icon>
              <v-icon v-if="item.prioridade === 2" color="prioridade_normal">
                mdi-flag Normal
              </v-icon>
              <v-icon v-if="item.prioridade === 3" color="prioridade_urgente">
                mdi-flag Urgente
              </v-icon>
              <v-icon
                v-if="item.prioridade === 4"
                color="prioridade_emergencia"
              >
                mdi-flag Emergência
              </v-icon>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card-text>

      <PdfDialogComp
        v-if="pdfDialog"
        :pdfDialog.sync="pdfDialog"
        :pdfData="pdfData"
        title="RELATÓRIO DURAÇÃO DAS ATIVIDADES"
      />
    </v-card>
  </v-container>
</template>

<script>
import { fetchTotalAtividadesClientes } from "@/api/cliente/relatorios/atividades_clientes.js";
const pdfGenerator = () =>
  import(/* webpackChunkName: "relatorio" */ "@/helpers/pdf/geral.js");
import {
  parseISO,
  format,
  addMonths,
  subMonths,
  startOfMonth,
  lastDayOfMonth,
} from "date-fns";
import { mapState } from "vuex";

export default {
  name: "RelatoriosAtividades",

  props: {
    cliente_id: {
      type: [Number, String],
    },
    clienteComponent: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    PdfDialogComp: () => import("@/components/PdfDialogComp.vue"),
  },

  data() {
    return {
      search: "",
      searchCliente: "",
      dialogAddAtividade: false,
      dialogEditAtividade: false,
      loadingCliente: false,
      listaAtividades: [],
      soma_totais: [],
      dataCalendario: format(new Date(), "yyyy-MM"),
      edit: false,
      atividadeSelected: null,
      pdfData: null,
      pdfDialog: false,
      clientes: [],
      menu: false,
      total_trabalhado: null,
    };
  },
  watch: {
    dataCalendario() {
      if (this.dataCalendario) {
        this.buscaCliente(this.dataCalendario + "-01");
      }
    },
  },

  computed: {
    headers() {
      return [
        {
          text: "Titulo",

          value: "titulo",
        },
        {
          text: "Serviço",
          width: "",
          value: "servico",
        },
        {
          text: "Inicio",
          width: "",
          value: "inicio",
        },
        {
          text: "Entrega",
          width: "",
          value: "entrega",
        },
        {
          text: "Total Tempo Atividade",

          value: "total",
        },
      ];
    },
    ...mapState("Usuario", {
      user_id: (state) => state.usuario.id,
    }),
    mostraMes() {
      let date = this.dataCalendario + "01";
      return format(parseISO(date), "MMM");
    },
  },

  methods: {
    addMonth() {
      this.dataCalendario = format(
        addMonths(parseISO(this.dataCalendario), 1),
        "yyyy-MM"
      );
    },
    subMonth() {
      this.dataCalendario = format(
        subMonths(parseISO(this.dataCalendario), 1),
        "yyyy-MM"
      );
    },
    buscaCliente(item1) {
      this.listaAtividades = [];
      this.getAtividades(
        `?dataFrom=${item1}&status=${7}&user_id=${this.user_id}`
      );
    },

    zeroPad(num) {
      let str = String(num);
      if (str.length < 2) {
        return "0" + str;
      }

      return str;
    },
    totalTimeString(timeStrings) {
      let totals = timeStrings.reduce(
        function(a, timeString) {
          let parts = timeString.split(":");
          let temp;
          if (parts.length > 0) {
            temp = Number(parts.pop()) + a.seconds;
            a.seconds = temp % 60;
            if (parts.length > 0) {
              temp = Number(parts.pop()) + a.minutes + (temp - a.seconds) / 60;
              a.minutes = temp % 60;
              a.hours = a.hours + (temp - a.minutes) / 60;
              if (parts.length > 0) {
                a.hours += Number(parts.pop());
              }
            }
          }

          return a;
        },
        {
          hours: 0,
          minutes: 0,
          seconds: 0,
        }
      );

      // returned string will be HH(H+):mm:ss
      return [
        this.zeroPad(totals.hours),
        this.zeroPad(totals.minutes),
        this.zeroPad(totals.seconds),
      ].join(":");
    },

    async getAtividades(filtro) {
      this.loading = true;

      return fetchTotalAtividadesClientes(this.user_id, filtro ? filtro : "")
        .then((response) => {
          this.listaAtividades = response.atividades_tempos;
          this.soma_totais = response.soma_totais;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    pdfContent() {
      let content = [];
      let count = 0;
      let objeto_cabecario = {
        margin: [0, 0, 0, 10],
        fontSize: 8,
        columns: [
          {
            text: "OLE DESIGNER & COMUNICAÇÃO",
            alignment: "left",
          },
          {
            text:
              "CRUZ E BETTO PRESTADORA DE SERVICOS EM DESIGN LTDA | CNPJ: 08.004.795/0001-18 | Telefone" +
              "(45) 3025-7263 | Endereço Rua Engenheiro Rebouças, 434 | Bairro: Centro | CEP: 85851-190 | Cidade: Foz" +
              "do Iguaçu | Estado: PR",
            alignment: "center",
          },
          {
            text: "RELATÓRIO DURAÇÃO DAS ATIVIDADES",
            fontSize: 16,
            bold: true,
            alignment: "center",
          },
        ],
      };
      content.splice(count, 0, objeto_cabecario);
      count += 1;

      let date = this.dataCalendario + "01";
      let objeto_cabecario2 = {
        fontSize: 8,
        margin: [0, 10, 0, 10],
        columns: [
          {
            text: "Cliente: " + this.listaAtividades[0].razao_social,
            bold: true,
            alignment: "center",
          },
          {
            text:
              "Início De: " +
              format(startOfMonth(new Date(parseISO(date))), "dd/MM/yyyy"),
            bold: true,
            alignment: "center",
          },
          {
            text:
              "Até: " +
              format(lastDayOfMonth(new Date(parseISO(date))), "dd/MM/yyyy"),
            bold: true,
            alignment: "center",
          },
        ],
      };
      content.splice(count, 0, objeto_cabecario2);
      count += 1;
      let tabela_header = [];

      for (let count = 0; count < 5; count++) {
        let line_header = ["ATIVIDADE", "SERVICO", "INÍCIO", "FIM", "TOTAL"];
        let obj = {
          text: line_header[count],
          colSpan: 1,
          fillColor: "#607D8B",
          color: "white",
          bold: true,
          fontSize: 8,
          alignment: "center",
        };

        tabela_header.splice(count, 0, obj);
      }
      let tabela_corpo = [];
      tabela_corpo.push(tabela_header);
      this.listaAtividades.forEach((atividade) => {
        let body_line = [
          {
            text: atividade.titulo,
            fontSize: 8,
            fillColor: "",
            alignment: "center",
          },
          {
            text: atividade.servico,
            fontSize: 8,
            fillColor: "",
            alignment: "center",
          },
          {
            text: format(parseISO(atividade.inicio), "dd/MM/yyyy"),
            fontSize: 8,
            fillColor: "",
            alignment: "center",
          },
          {
            text: format(parseISO(atividade.entrega), "dd/MM/yyyy"),
            fontSize: 8,
            fillColor: "",
            alignment: "center",
          },
          {
            text: atividade.total,
            fontSize: 8,
            fillColor: "",
            alignment: "center",
          },
        ];
        //
        tabela_corpo.push(body_line);
      });
      let obj_tempo = {
        margin: [0, 0, 0, 10],
        table: {
          widths: ["*", "*", "*", "*", "*"],
          headerRows: 1,
          body: tabela_corpo,
        },
        layout: {
          hLineWidth: function(i, node) {
            return i <= 1 || i === node.table.body.length ? 1 : 0;
          },
          vLineWidth: function(i, node) {
            return i === 0 || i === node.table.widths.length ? 0 : 1;
          },
          hLineColor: function(i, node) {
            return i === 0 || i === node.table.body.length
              ? "#CFD8DC"
              : "#CFD8DC";
          },
          vLineColor: function(i, node) {
            return i === 0 || i === node.table.widths.length
              ? "#CFD8DC"
              : "#CFD8DC";
          },
          fillColor: function(rowIndex) {
            return rowIndex > 0 && rowIndex % 2 === 0 ? "#E0E0E0" : null;
          },
        },
      };

      content.splice(count, 0, obj_tempo);

      let total_tempo_total = {
        columns: [
          {
            text: "TOTAL HORAS: " + this.soma_totais[0].total,
            fontSize: 8,

            bold: true,
            alignment: "right",
          },
        ],
      };
      count += 1;
      content.splice(count, 0, total_tempo_total);
      return content;
    },

    async gerarpdf() {
      try {
        this.loading = true;
        this.$Progress.start();

        await pdfGenerator()
          .then((module) => {
            let generate = module.generatePdf;
            generate(this.pdfContent()).then((response) => {
              this.pdfData = response;
              this.pdfDialog = true;
            });
          })
          .finally(() => {
            this.loading = false;
            this.$Progress.finish();
          });
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.$Progress.fail();
      }
    },
  },
  async mounted() {
    await this.getAtividades(
      `?dataFrom=${this.dataCalendario + "-01"}&status=${7}&user_id=${
        this.user_id
      }`
    );
  },
};
</script>

<style lang="scss"></style>
